import React from 'react';

// const loadScript = () => {
//     const script = document.createElement('script');
//     script.async = true;
//     script.id = '_cn_generic_script4eab30bf-1633-42e4-91db-62de01483632';
//     script.text = `
//     !function(e){function t(){if(!e.querySelector("#_cn_client_script_4eab30bf-1633-42e4-91db-62de01483632")){var t=e.createElement("script");t.setAttribute("src","https://cdn.connectif.cloud/eu1/client-script/4eab30bf-1633-42e4-91db-62de01483632") ,e.body.appendChild(t)}}"complete"===e.readyState||"interactive"===e.readyState?t():e.addEventListener("DOMContentLoaded",t)}(document);
//     `;
//   var xx = document.getElementsByTagName('body')[0];
//   xx.appendChild(script);
//   return script;
// }

// const removeScript = (script) => {
//     document.body.removeChild(script);
// }

const Encuesta = () => {

    // useEffect(() =>{
    //    const script = loadScript();
    //    return () => removeScript(script);
    // },[])

    return (<></>)
}
  

export default Encuesta;

